// App main
import 'virtual:svg-icons-register';
import '@/css/app.pcss';
import '@/css/hamburgers.scss';
import 'swiper/css';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'nouislider/dist/nouislider.min.css';


import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';
import collapse from '@alpinejs/collapse';

Alpine.plugin(collapse);
Alpine.plugin(intersect);

window.Alpine = Alpine;

function isExternalLink(url) {
    const tmp = document.createElement('a');
    tmp.href = url;
    return tmp.host !== window.location.host;
};

function isTouch() {
    // @ts-ignore
    return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
}

const isUUID = (uuid = null) => {
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return regexExp.test(uuid); // true
}

function currency(number, end = true) {
    if (!number) {
        number = 0;
    }
    number = isNaN(number) ? number.toString().replace(/\s+/g, '') : number;
    return new Intl.NumberFormat('nb-NO').format(number) + (end ? ',-' : '');
};
const main = async () => {
    // Async load the vue module
    const {default: dayjs} = await import('dayjs');
    const {default: localizedFormat} = await import('dayjs/plugin/localizedFormat');
    const {default: relativeTime} = await import('dayjs/plugin/relativeTime');
    const {Navigation, Pagination, Swiper} = await import('swiper');
    Swiper.use([Navigation, Pagination]);
    dayjs.extend(localizedFormat);
    dayjs.extend(relativeTime);
    await import('dayjs/locale/nb');
    dayjs.locale('nb');

    return {dayjs, Swiper};
};

// Execute async function
main().then(modules => {
    window["dayjs"] = modules.dayjs;
    window["app"] = {
        isUUID,
        currency(number, end = false) {
            return currency(number, end)
        },
        placeholder: (width = 0, height = 0) => {
            return `data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='${width}' height='${height}' viewBox='0 0 ${width} ${height}'><rect width='${width}' height='${height}'></rect></svg>`;
        },
        initProsjekt() {
            return {
                hash: null,
                small: false,
                verdivurdering: false,
                init() {
                    this.$watch('verdivurdering', (value) => this.overlay = value);
                    this.hash = window.location.hash.replace('#', '') || null;
                    if (this.hash) {
                        setTimeout(() => {
                            window.app.scrollToId(`scroll-${this.hash.split("/")[0]}`)
                        }, 300)
                    }
                    this.$watch('hash', (value) => {
                        window.app.scrollToId(`scroll-${value.replace('#', '').split("/")[0]}`)
                    });

                },
                async copyUrl(startUrl = null, startHash = null) {
                    try {
                        let content = this.$el.innerHTML;
                        let url = new URL(startUrl || location.href);
                        url.hash = startHash || null

                        if (url.protocol == 'https:') {
                            await navigator.clipboard.writeText(url.href);
                        } else {
                            window.location.href = url.href;
                        }
                        this.$el.innerHTML = '<small>Kopiert!</small>';
                        setTimeout(() => {
                            this.$el.innerHTML = content;
                        }, 1000);
                        console.log('Page URL copied to clipboard');
                    } catch (err) {
                        console.error('Failed to copy: ', err);
                    }
                }
            }
        },
        leilighetsvelger(id) {
            return {
                prosjekt: [],
                visning: 'bilde',
                valgtSteg: null,
                valgtEnhet: null,
                enheterFiltrert: null,
                lasteError: null,
                visLedige: false,
                zoom: false,
                visFilter: false,
                variabler: null,
                innstillinger: null,
                filter: {
                    pris: {min: null, max: null},
                    storrelse: {min: null, max: null},
                    soverom: {min: null, max: null}
                },
                async init() {
                    const {default: tippy} = await import('tippy.js');

                    this.$refs.mobilEnhet.innerHTML = this.$refs.enhet.innerHTML;

                    this.$watch('visFilter', (value) => this.overlay = value);
                    this.$watch('prosjekt', () => {
                        // console.log('endret', value);
                        this.prosjekt.steg.forEach((steg) => {
                            const variabler = steg.variabler;
                            document.querySelectorAll(`[data-id="${steg.byggID}" ]`).forEach((elm) => {
                                if (variabler.ledig == 0 && variabler.reservert == 0 && variabler.solgt > 0) {
                                    elm.classList.add('solgt');
                                }
                            })
                        })

                        tippy('.bygg', {
                            theme: 'light',
                            content: 'Laster...',
                            touch: false,
                            allowHTML: true,
                            onCreate: (instance) => {
                                const target = instance.reference,
                                    steg = this.prosjekt.steg.find(steg => steg.byggID == target.dataset.id),
                                    innstillinger = steg?.innstillinger,
                                    variabler = steg?.variabler;

                                if (steg) {
                                    instance.setContent(`
                                        <strong class="text-center block">${steg.title}</strong>
                                        <hr class="my-2 w-10 mx-auto opacity-75"/>
                                        <ul class="flex flex-col text-center">
                                            <li class="${(!innstillinger.visLedigSolgt || variabler.ledig == 0) && 'hidden'}">${variabler.ledig} ledig</li>
                                            <li class="${variabler.reservert == 0 && 'hidden'}">${variabler.reservert} reservert</li>
                                            <li class="${(!innstillinger.visLedigSolgt || variabler.solgt == 0) && 'hidden'}">${variabler.solgt} solgt</li>
                                        </ul>
                                        <small class="text-gray-600 bg-gray-100 -mx-2 -mb-1 mt-2 p-2 max-w-[200px] block ${!steg.ekstraInfo && 'hidden'}">${steg.ekstraInfo}</small>
                                    `);
                                } else {
                                    instance.setContent('Ingen feed');
                                }
                            }
                        })
                    })

                    this.$watch('valgtSteg', (steg) => {
                        // console.log('steg', steg);
                        // console.table(this.innstillinger);
                        if (!steg) return;

                        steg.retning.forEach((retning) => {
                            let img = new Image();
                            img.srcset = retning.bilde;
                        });
                        steg.enheter.forEach((enhet) => {
                            document.querySelectorAll(`[data-id="${enhet.husnr}" ]`).forEach((elm) => {
                                elm.classList.add('aktivert');
                                if (enhet.solgt) {
                                    elm.classList.add('solgt');
                                } else if (enhet.status.toLowerCase() == 'reservert') {
                                    elm.classList.add('reservert');
                                }
                                elm.addEventListener("click", (event) => {
                                    event.preventDefault();
                                    let elm = event.target;
                                    this.velgEnhet(elm.dataset.id);
                                });
                            })
                        })

                        tippy('.aktivert', {
                            theme: 'light',
                            content: '',
                            touch: false,
                            allowHTML: true,
                            onCreate: (instance) => {
                                const target = instance.reference,
                                    enhet = steg.enheter.find(enhet => enhet.husnr == target.dataset.id),
                                    thumb = enhet?.bilder[0]?.sizes[0].url || null,
                                    // variabler = steg.variabler,
                                    farge = enhet.status == 'Reservert' ? 'text-yellow' : (enhet.status == 'Solgt' ? 'text-red' : 'text-green'),
                                    storrelse = enhet.storrelser?.braI || enhet.storrelse;

                                instance.setContent(`
                                    <picture class="aspect-video w-32 mb-2 -mx-1 block border border-opacity-[5%] overflow-hidden rounded border-svart relative">
                                         <svg class="absolute w-8 h-8 fill-hoved/50 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                                            <use href="#icon-alt"></use>
                                        </svg>
                                        <img class="object-cover lazyload relative" data-src="${thumb || ''}" />
                                    </picture>
                                    <strong class="block ${farge}">${enhet.husnr}</strong>
                                    <ul class="flex flex-col">
                                        <li>Pris: <strong>${!enhet.solgt ? currency(enhet.prisantydning) : 'Solgt'}</strong></li>
                                        <li>Størrelse: <strong>${storrelse || '-'} m<sup>2</sup></strong></li>
                                        <li>Soverom: <strong>${enhet.soverom || '-'}</strong></li>
                                    </ul>
                                `);
                            }
                        })
                    });

                    this.$watch('valgtEnhet', (enhet) => {
                        // console.log('valgtEnhet', enhet, old);
                        document.querySelectorAll('.aktivert').forEach((el) => {
                            el.classList.remove('!opacity-90');
                        });
                        if (enhet) {
                            document.querySelectorAll(`[data-id="${enhet.husnr}" ]`).forEach((el) => {
                                el.classList.add('!opacity-90');
                            });
                        }
                    });

                    this.$watch('filter', (filter) => {
                        this.enheterFiltrert = this.valgtSteg.enheter.filter((enhet) => {
                            const checkStor = enhet.storrelser?.braI || enhet.storrelse;
                            let pris = filter.pris.min && filter.pris.max ? (enhet.prisantydning >= filter.pris.min && enhet.prisantydning <= filter.pris.max && !enhet.solgt) : true,
                                storrelse = filter.storrelse.min && filter.storrelse.max ? (checkStor >= filter.storrelse.min && checkStor <= filter.storrelse.max) : true,
                                soverom = filter.soverom.min && filter.soverom.max ? (enhet.soverom >= filter.soverom.min && enhet.soverom <= filter.soverom.max) : true;
                            return pris && storrelse && soverom;
                        }).map(enhet => enhet.husnr);
                    });

                    this.$watch('enheterFiltrert', (husnr) => {
                        document.querySelectorAll('.aktivert').forEach((el) => {
                            el.classList.add('skjul');
                        });

                        husnr.forEach((item) => {
                            document.querySelectorAll(`[data-id="${item}" ]`).forEach((el) => {
                                el.classList.remove('skjul');
                            });
                        });
                    });

                    this.$refs.velger.addEventListener("click", (event) => {
                        let elm = event.target;
                        if (elm.classList.contains('bygg')) {
                            this.velgBygg(elm.dataset.id);
                        }
                    });
                },
                velgBygg(byggID, husnr = null, force = false) {
                    if (this.valgtSteg?.byggID == byggID && !force) return;
                    this.velgEnhet(null);
                    this.valgtSteg = null;
                    this.$nextTick(() => {
                        this.valgtSteg = this.prosjekt.steg.find(steg => steg.byggID == byggID) || null;
                        this.enheterFiltrert = this.valgtSteg?.enheter.map(enhet => enhet.husnr) || [];
                        setTimeout(() => {
                            const el = document.getElementById('scrollVelger'),
                                active = el?.querySelector('.active'),
                                left = active ? (active.offsetLeft - (window.innerWidth / 2) + (active.clientWidth / 2)) : 0;

                            el?.scrollTo({
                                left: left,
                                behavior: 'smooth'
                            });
                        }, 200)
                        if (husnr) {
                            this.velgEnhet(husnr);
                        }
                    });
                },
                velgEnhet(husnr) {
                    this.visFilter = false;
                    this.zoom = false;
                    this.valgtEnhet = this.valgtSteg?.enheter.find(enhet => enhet.husnr == husnr) || null;
                },
                table(data = this.valgtSteg?.enheter || []) {
                    return {
                        sorted: null,
                        sortCol: null,
                        sortAsc: false,
                        init() {
                            this.sorted = data.map((item) => {
                                item.prisantydning = item.solgt ? null : item.prisantydning;
                                return item;
                            });
                            this.$el.querySelectorAll('th').forEach((item) => {
                                item.setAttribute('role', 'columnheader');
                            });
                            this.sort('husnr');
                        },
                        sort(col) {
                            if (this.sortCol === col) this.sortAsc = !this.sortAsc;
                            this.sortCol = col;
                            this.$root.querySelector('[aria-sort]')?.removeAttribute('aria-sort');
                            this.$el.setAttribute('aria-sort', this.sortAsc ? 'ascending' : 'descending');
                            this.sorted.sort((a, b) => {
                                a = a[this.sortCol];
                                b = b[this.sortCol];
                                // equal items sort equally
                                if (a === b) {
                                    return 0;
                                }
                                // nulls sort after anything else
                                else if (a === null) {
                                    return 1;
                                } else if (b === null) {
                                    return -1;
                                }
                                // otherwise, if we're ascending, lowest sorts first
                                else if (this.sortAsc) {
                                    return a < b ? -1 : 1;
                                }
                                // if descending, highest sorts first
                                else {
                                    return a < b ? 1 : -1;
                                }
                            });
                        },
                    }
                },
                slider(type) {
                    return {
                        max: null,
                        min: null,
                        async init() {
                            const nouislider = await import('nouislider');

                            let variabel = this.valgtSteg.variabler[type],
                                fra = variabel[0],
                                til = variabel[1];

                            // console.log(type, fra, til)

                            this.min = fra;
                            this.max = til;

                            let slider = nouislider.create(this.$refs.slider, {
                                start: [fra, til],
                                connect: true,
                                range: {
                                    'min': [fra, type == 'pris' ? 100000 : 1],
                                    'max': [til]
                                },
                            });

                            slider.on('set', (value) => {
                                // console.log('set', value);
                                if (value[0] == fra && value[1] == til) {
                                    this.filter[type].min = null;
                                    this.filter[type].max = null;
                                } else {
                                    this.filter[type].min = parseInt(value[0]);
                                    this.filter[type].max = parseInt(value[1]);
                                }
                            });

                            slider.on('update', (value) => {
                                this.min = value[0];
                                this.max = value[1];
                            });
                        }
                    }
                },
                async hent() {
                    if (!id) return false;
                    let prosjekt = null;
                    try {
                        let {data} = (await (await fetch(`/api/craft-prosjekt/${id}`)).json());
                        prosjekt = data;
                    } catch (error) {
                        this.lasteError = error;
                    }

                    if (prosjekt) {
                        let velger = document.createElement('div');
                        velger.id = 'temp-svg';
                        velger.style.display = 'none';
                        velger.innerHTML = prosjekt.svg;
                        document.body.appendChild(velger);
                        prosjekt.steg = prosjekt.steg.map((steg) => {
                            const elm = document.querySelector(`[data-id="${steg.byggID}" ]`);
                            steg.title = elm?.getAttribute("xlink:title") || steg.byggID || steg.tittel;
                            // console.log(steg.byggID)
                            /*let priser = [...new Set(steg.enheter.filter(enhet => !enhet.solgt).map(enhet => enhet.prisantydning))];
                            steg.variabler.pris = priser.length >= 2 ? [Math.min.apply(Math, priser), Math.max.apply(Math, priser)] : (priser[0] ?? null);*/
                            steg.byggID = steg.byggID || 'bygg';
                            return steg;
                        });
                        prosjekt.visOversikt = !(!prosjekt.bilde && !prosjekt.svg);
                        document.getElementById('temp-svg').remove();
                        this.prosjekt = prosjekt;
                        this.estateIds = prosjekt.steg.filter(steg => steg.source === 'vitec').map((steg) => {
                            return {"id": steg.id, "title": steg.title, "lenker": steg.lenker}
                        });
                        const hashArray = this.hash?.split("/") || [];
                        const bygg = prosjekt.steg.length == 1 && !prosjekt.visOversikt ? this.prosjekt.steg[0].byggID : (hashArray[1] || null);
                        const enhet = hashArray[2] || null;
                        console.log(`Bygg: ${bygg}`, `Enhet: ${enhet}`, prosjekt.visOversikt);
                        /*if (hashArray.length > 1) {
                            this.velgBygg(hashArray[1], hashArray[2], true);
                        } else if (prosjekt.steg.length == 1 && !prosjekt.visOversikt) {
                            this.velgBygg(this.prosjekt.steg[0].byggID, null, true);
                        }*/

                        // this.velgBygg(bygg, enhet, true);
                        if (bygg || enhet) {
                            this.velgBygg(bygg, enhet, true);
                        }
                    }
                },
                async fjernProsjektCache() {
                    // let prosjekt = (await (await fetch('/api/prosjekt?id=' + id)).json()).data || null;
                    this.laster = true;
                    try {
                        console.log(`Fjerner cache på ${id}`);
                        (await (await fetch(`/api/clear-prosjekt-cache/${id}`)).json());
                        this.hent();
                    } catch (error) {
                        console.error('Error:', error);
                    }
                    this.laster = false;
                }
            }
        },
        scrollToId(id) {
            console.log(id);
            const element = document.getElementById(id);
            if (element) {
                const y = (element.getBoundingClientRect().top + window.pageYOffset) - 130;
                window.scrollTo({top: y, behavior: 'smooth'});
            }
        },
        currentScroll() {
            return {
                currentIndex: null,
                anchors: [],
                init() {
                    setTimeout(() => {
                        this.check();
                    }, 500);
                },
                setup() {
                    this.anchors = Object.values(this.$el.children).map((item) => {
                        const el = document.getElementById(item.dataset.id);
                        // console.log(item, el);
                        return parseInt(el.getBoundingClientRect().top + window.scrollY - 130);
                    });
                    this.anchors.push(document.documentElement.scrollHeight);
                    // console.log(this.$el.children, this.anchors);
                },
                check() {
                    this.setup();
                    this.currentIndex = this.anchors.indexOf(this.anchors.find(item => item > window.pageYOffset)) - 1;
                    this.$nextTick(() => {
                        const el = this.$root.querySelector('.underline');
                        if (el) {
                            el.scrollIntoView({behavior: "smooth", inline: "center"});
                        }
                    })
                }
            }
        },
        slideshow(type) {
            return {
                slider: null,
                setup() {
                    let options = {
                        loop: true,
                        pagination: {
                            el: '.pagination',
                            type: 'bullets',
                            bulletActiveClass: 'bg-hoved',
                            bulletClass: 'rounded-full w-3 h-3 border-2 border-hoved',
                            clickable: true,
                            clickableClass: 'cursor-pointer'
                        },
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev'
                        }
                    }
                    if (type === 'v3') {
                        options.centeredSlides = true;
                        options.slidesPerView = "auto";
                    }

                    this.slider = new modules.Swiper(this.$el, options);
                    // this.slider.update();
                    this.$watch('bilder', value => {
                        console.log(value);
                        this.slider.update();
                        this.slider.slideTo(0);
                    })
                },
                bilder() {
                    return this.valgtEnhet?.bilder || {};
                },
                srcSet(bilde) {
                    if (!bilde) return null;
                    return bilde?.sizes.map(size => size.url + ' ' + size.width + 'w');
                }
            }
        },
        initZoom() {
            return {
                move(e) {
                    const zoomer = e.currentTarget;
                    if (!zoomer.classList.contains('group')) return;

                    const offsetX = e?.offsetX || e?.touches[0].pageX,
                        offsetY = e?.offsetY || e?.touches[0].pageY;

                    let x = offsetX / zoomer.offsetWidth * 100;
                    let y = offsetY / zoomer.offsetHeight * 100;
                    zoomer.style.backgroundPosition = x + '% ' + y + '%';
                    // console.log(zoomer.style.backgroundPosition)
                },
                enter(url) {
                    if (!url) return;
                    this.$el.style.backgroundImage = `url('${encodeURI(url)}')`;
                    this.$el.classList.add('group', 'cursor-zoom-in')
                },
                leave() {
                    this.$el.style = '';
                }
            }
        },
        kart(options = {lat: null, lng: null, zoom: 15, marker: null, style: null}) {
            return {
                settings: options,
                async setup() {
                    console.log('init kart');
                    const {Loader} = await import('@googlemaps/js-api-loader');
                    const google = await new Loader({
                        apiKey: "AIzaSyCqSgc3TrU9nn-GXcO-wJg3Af8RZhHdvIM",
                        version: "weekly"
                    }).load();

                    let latLng = new google.maps.LatLng(options.lat, options.lng);
                    let mapOptions = {
                        center: latLng,
                        zoom: options.zoom,
                        scrollwheel: false,
                        draggable: !isTouch(),
                        panControl: false
                    };

                    let map = new google.maps.Map(this.$el, mapOptions);
                    new google.maps.Marker({
                        position: latLng,
                        map: map,
                        icon: options.marker,
                    });

                    if (options.style) {
                        map.setOptions({styles: JSON.parse(options.style)});
                    }
                }
            }
        },
        edit(id) {
            return {
                lenke: null,
                id: id,
                init() {
                    this.hentEdit();
                    this.$watch('csrf', (value) => {
                        this.hentEdit();
                    });
                },
                async hentEdit() {
                    if (!this.csrf || this.user.isGuest || this.id == null) {
                        return false;
                    }

                    this.lenke = (await (await fetch('/api/edit?ids=' + this.id)).json()).data[0] || null;
                }
            }
        },
        hentSession() {
            return {
                showAdmin: true,
                user: {
                    isGuest: true,
                    isAdmin: false
                },
                csrf: null,
                async init() {
                    await fetch('/actions/users/session-info', {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                        }
                    })
                        .then(res => res.json())
                        .then(async (json) => {
                            this.user = json;
                            this.csrf = json.csrfTokenValue;
                            this.user.isAdmin = ['@mustasj.no'].find(item => json.email?.includes(item)) !== undefined
                            if (!this.user.isGuest) {
                                await import('@alenaksu/json-viewer');
                                try {
                                    Sentry.setUser({
                                        username: this.user.username,
                                        email: this.user.email
                                    });
                                } catch (error) {
                                    console.warn(error);
                                }
                                if (document.querySelector('main')) {
                                    this.adminbar();
                                }
                            }
                        }).catch((error) => {
                            console.error('Error:', error);
                        });

                    this.showAdmin = (localStorage.getItem('showAdmin') === 'true');
                    this.$watch('showAdmin', (value) => {
                        localStorage.setItem('showAdmin', value)
                    })

                },
                async adminbar() {
                    const {default: adminbar} = await import('@/template/adminbar.html?raw');
                    var elem = document.createElement('div');
                    elem.innerHTML = adminbar;
                    document.body.appendChild(elem);
                }
            }
        },
        initCacheDetection() {
            return {
                hasCache: false,
                caches: [],
                async setup(checkHead: boolean = false) {
                    if (checkHead) {
                        const url = new URL(window.location.pathname, window.location.href);
                        const {headers} = await fetch(url, {method: 'HEAD', headers: {'Accept': 'application/json'}});
                        if (headers.get('Server') === 'cloudflare') {
                            this.caches.push({
                                label: 'Cloudflare',
                                status: headers.get('Cf-Cache-Status') || null,
                                time: headers.get('Last-Modified') || null
                            });
                        }
                    }

                    const isBlitz = (document.lastChild.nodeValue ?? '').includes('Blitz');
                    if (isBlitz) {
                        const blitzString = document.lastChild.nodeValue.toString().trim();
                        this.caches.push({
                            label: 'Blitz',
                            status: blitzString.includes('Cached by') ? 'HIT' : 'UNKNOWN',
                            time: blitzString.split(' ').pop()
                        });
                    }

                    this.hasCache = this.caches.length > 0;
                    // console.info('cache', this.hasCache, this.caches);

                },
                async refreshUrlCache(url: string = null) {
                    const refreshUrl = url ? new URL(url) : new URL(window.location.pathname, window.location.href);
                    console.log(window.location, refreshUrl.toString())
                    this.sender = true;
                    try {
                        await fetch('/actions/blitz/cache/refresh-urls', {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'X-CSRF-Token': this.csrf,
                                "Content-Type": "application/json",
                                'X-Requested-With': 'XMLHttpRequest'
                            },
                            body: JSON.stringify({
                                key: 'hvn2dhq8geq6BDJ!fgh',
                                urls: [refreshUrl.toString()]
                            })
                        });
                    } catch (e) {
                        console.error(e.message)
                    }
                    this.sender = false;
                },
                async refreshTagCache(tag: string = null) {
                    console.log(tag)
                    this.sender = true;
                    try {
                        await fetch('/actions/blitz/cache/refresh-tagged', {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'X-CSRF-Token': this.csrf,
                                "Content-Type": "application/json",
                                'X-Requested-With': 'XMLHttpRequest'
                            },
                            body: JSON.stringify({
                                key: 'hvn2dhq8geq6BDJ!fgh',
                                tags: [tag.toString()]
                            })
                        });
                    } catch (e) {
                        console.error(e.message)
                    }
                    this.sender = false;
                },
            }
        },
        initSkjema() {
            return {
                visSuccess: false,
                successMessage: 'Takk for din interesse, vår megler vil ta kontakt med deg så fort det lar seg gjøre.',
                visErrors: false,
                debug: null,
                sender: false,
                errors: [],
                async send() {
                    this.sender = true;
                    this.errors = [];
                    const data = new FormData(this.$el);
                    const action = this.$el.getAttribute('action') || '/';
                    let options = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'X-CSRF-Token': this.csrf,
                        },
                        body: data
                    }
                    if (isExternalLink(action)) {
                        delete options.headers['X-CSRF-Token'];
                    }
                    // console.log(data, action);
                    await fetch(action, options)
                        .then(res => res.json())
                        .then(json => {
                            // console.log(json);
                            this.visErrors = false;
                            if (json.success) {
                                if (json.successMessage) {
                                    this.successMessage = json.successMessage;
                                }
                                this.visSuccess = true;
                            }
                            if (json.errors || json.error) {
                                this.errors = json.errors || [json.error.message || json.error];
                                this.visErrors = true;
                            }

                            if (!this.user.isGuest) {
                                this.debug = json.errors || json.error || json.data || json;
                            }

                            if (json.returnUrl && json.onSuccess == 'redirect-return-url') {
                                window.location = json.returnUrl;
                            }
                        }).catch((error) => {
                            console.error('Error:', error);
                            this.errors = [error];
                            this.visErrors = true;
                        }).then(() => {
                            this.sender = false;
                        });
                },
                async getEstateIds(id = null) {
                    console.log(id);
                    this.estateIds = [];
                }
            }
        }
    };
    Alpine.start();
});
